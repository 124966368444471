import React from "react"
import styles from "./404.module.scss"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import Footer from "../components/footer"

export default ({ location }) => {
	const intl = useIntl()
	return (<>
		<div className={styles.ctr}>
			<SEO
				title={intl.formatMessage({ id: "page.not_found.name" })}
				pathname={location.pathname}
				siteName="site.short_name" />
			<header className={styles.header}><FormattedMessage id="page.not_found.name" /></header>
			<div className={styles.entry}>
	      <div className={styles.message}><FormattedMessage
	      	id="page.not_found.desc"
	      	values={{ url: location.pathname }} /></div>
	      <Link to="/" className={styles.home}><FormattedMessage id="home" /></Link>
	    </div>
		</div>
		<Footer />
	</>)
}